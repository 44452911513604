import React from "react"

import AboutLayout from "./../components/about/AboutLayout"
import SEO from "../components/seo"

import AboutImage from "../components/AboutImage"
import BackgroundImage from "../components/BackgroundImage"

import styled from "styled-components"

const Styled = styled.div`
  section {
    padding: 3.2rem;
    max-width: 1100px;
    margin: 0 auto;

    p {
      font-size: 2.2rem;
    }

    .about-text {
      color: #ff4500;
      font-family: "Special Elite", cursive;

      h2 {
        font-family: "Special Elite", cursive;
      }
    }

    .about-text-and-image {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 2em 0;
      padding: 2em 0;

      &.center {
        width: 100%;
        justify-content: center;
        padding-top: 2em;

        .text {
          max-width: 100%;
          text-align: center;
        }
      }

      .text {
        /* font-size: 1rem; */
        max-width: 600px;
        padding-right: 1.8em;
        font-family: "Special Elite", cursive;
      }

      .image {
        max-width: 300px;
        margin: 0 1.2rem;

        .photo-caption {
          width: 100%;
          font-size: 0.8rem;
          line-height: 1.2em;
          opacity: 0.8;
          margin: 0.5em 0;
        }
      }
    }
  }

  @media (max-width: 600px) {
    section {
      /* padding: 2em; */

      h2 {
        font-size: 1.6rem;
      }

      p {
        font-size: 1.4rem;
      }

      .about-text-and-image {
        flex-direction: column;
        /* margin-top: 0; */
        padding: 0;

        .image {
          margin: 1em 0;
        }

        .text {
          padding: 0;
        }
      }
    }
  }
`

const AboutPage = () => (
  <AboutLayout>
    <SEO title="Home" />
    <Styled>
      <BackgroundImage isAbout="true">
        <section>
          <div class="about-text">
            <h2>Confidential.</h2>
            <p>
              September 12th, 1986. Government agents went to investigate a
              crash site deep in the New Mexico desert.
            </p>
            <p>
              In the smoking crater lay a newborn boy with eyes as blue as the
              oceans of this strange world he must now call home.
            </p>
            <p>
              Once the child was old enough to walk, he escaped the government
              facility that they locked him in.
            </p>
          </div>
        </section>
      </BackgroundImage>
      <section>
        <div class="about-text-and-image">
          <div class="text">
            <p>
              This child of the stars made it all the way to the place that most
              closely resembled his advanced society light years away.
            </p>
            <p>This near perfect settlement had a name...</p>
            <p>Bayonne, New Jersey.</p>
          </div>
          <div class="image">
            <AboutImage picture={`young`}></AboutImage>
            <div class="photo-caption">
              Steve learns to interact with 'family'. Bayonne, NJ. 1993.
            </div>
          </div>
        </div>
        <div class="about-text-and-image">
          <div class="image">
            <AboutImage picture={`withdoug`}></AboutImage>
            <div class="photo-caption">
              After a close escape from government agents. Orange, NJ. 1996.
            </div>
          </div>

          <div class="text">
            <p>Life took him through the normal human course...</p>
            <p>High school. College. Graduation into the Great Recession.</p>
          </div>
        </div>
        <div class="about-text-and-image">
          <div class="text">
            <p>
              After college he traveled the world for Earth's biggest companies,
              studying how people shopped.
            </p>
          </div>

          <div class="image">
            <AboutImage picture={`methodology`}></AboutImage>
            <div class="photo-caption">
              Steve learning what makes people human. Houston, TX. 2012.
            </div>
          </div>
        </div>
        <div class="about-text-and-image">
          <div class="image">
            <AboutImage picture={`japan`}></AboutImage>
            <div class="photo-caption">
              Steve assimilating seamlessly with other Earthen cultures. Tokyo,
              Japan. 2015.
            </div>
          </div>
          <div class="text">
            <p>
              His travels took him everywhere from rural Georgia to seven-story
              Tokyo Malls.
            </p>
          </div>
        </div>
        <div class="about-text-and-image">
          <div class="text">
            <p>
              As time progressed, Steve realized people lived more in digital
              worlds than this one. He abandoned his past to learn of this
              future.
            </p>
          </div>

          <div class="image">
            <AboutImage picture={`whiteboard`}></AboutImage>
            <div class="photo-caption">
              Just point and pretend you know what you're looking at. New York,
              2017.
            </div>
          </div>
        </div>
        <div class="about-text-and-image">
          <div class="image">
            <AboutImage picture={`bomb`}></AboutImage>
            <div class="photo-caption">
              Steve escapes an assassination attempt. Los Angeles, CA. 2015.
            </div>
          </div>
          <div class="text">
            <p>
              Steve still lives on the run, posing as a Software Engineer while
              governments around the world tear down entire cities to get back
              the knowledge in his extra and terrestrial mind.
            </p>
          </div>
        </div>
        <div class="about-text-and-image center">
          <div class="text">
            <p>The government will stop at nothing to get him back.</p>
          </div>
        </div>
        <div class="about-text-and-image center">
          <div class="text">
            <p>You can have him for a competitive salary.</p>
          </div>
        </div>
      </section>
    </Styled>
  </AboutLayout>
)

export default AboutPage
