import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Styled = styled.div`
  width: 300px;
`

const AboutImage = ({ picture, children }) => {
  const data = useStaticQuery(graphql`
    query {
      young: file(relativePath: { eq: "about/young-steve.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      methodology: file(relativePath: { eq: "about/methodology.jpeg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      withdoug: file(relativePath: { eq: "about/withdoug.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      whiteboard: file(relativePath: { eq: "about/whiteboard.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      japan: file(relativePath: { eq: "about/japan.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }

      bomb: file(relativePath: { eq: "about/bomb.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }
    }
  `)

  const fluid = data[picture].childImageSharp.fluid

  return (
    <Styled>
      <Img fluid={fluid}>{children}</Img>
    </Styled>
  )
}

export default AboutImage
