import React from "react"
import Layout from "./../layout"
// import AboutLinks from "./AboutLinks"
// import { Location } from "@reach/router"

const about = {
  display: "About",
  href: "/about",
}

const references = {
  display: "References",
  href: "/about/references",
}

const links = [about, references]

export default ({ children }) => (
  <Layout>{children}</Layout>
  // <Location>
  //   {({ location }) => {
  //     return (
  //       <Layout>
  //         <AboutLinks pathname={location.pathname} links={links}></AboutLinks>
  //         {children}
  //       </Layout>
  //     )
  //   }}
  // </Location>
)
